// services/saime.ts
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "https://premioscyt.mincyt.gob.ve/api";

interface SaimeMessage {
  origen: string;
  cedula: string;
  pais_origen: string;
  nacionalidad: string;
  primer_nombre: string;
  segundo_nombre: string;
  primer_apellido: string;
  segundo_apellido: string;
  fecha_nacimiento: string;
  naturalizado: number;
  sexo: string;
  fecha_registro: string | null;
  fecha_ult_actualizacion: string | null;
  id: string;
}

interface SaimeResponse {
  ok: boolean;
  token: string;
  data: {
    message: SaimeMessage;
  };
}

export const saimeService = async (params: { origen: string; cedula: string }): Promise<SaimeResponse> => {
  try {
    const response = await axios.post<SaimeResponse>(`${API_URL}/proxy/saime`, {
      cedula: params.cedula,
      origen: params.origen || "V",
    }, {
      headers: { "Content-Type": "application/json" },
    });

    if (!response.data.ok) {
      const errorMessage = response.data.data?.message
        ? typeof response.data.data.message === 'string'
          ? response.data.data.message
          : 'Error en la API de SAIME'
        : 'Error en la API de SAIME';
      throw new Error(errorMessage);
    }

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Error en saimeService:', error.response?.data || error.message);
      throw new Error(error.response?.data?.message || 'Error al consultar SAIME');
    }
    throw error;
  }
};
